import React from 'react';
import { Link } from 'gatsby';

// Animate Link arrow
function animArrowIn(event) {
  event.target.classList.add('in');
}

function animArrowOut(event) {
  event.target.classList.remove('in');
  event.target.classList.add('out');

  setTimeout(function () {
    event.target.classList.remove('out');
  }, 1000);
}

function AnimateLink({ externalLink, url, cssClassLink, children }) {
  return (
    <>
      {externalLink && (
        <a
          href={url}
          target="_blank"
          className={cssClassLink}
          rel="noreferrer"
          onMouseEnter={e => animArrowIn(e)}
          onMouseLeave={e => animArrowOut(e)}
        >
          {children}
        </a>
      )}

      {!externalLink && (
        <Link
          to={`/${url}`}
          className={cssClassLink}
          onMouseEnter={e => animArrowIn(e)}
          onMouseLeave={e => animArrowOut(e)}
        >
          {children}
        </Link>
      )}
    </>
  );
}

export default AnimateLink;
