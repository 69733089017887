import React from 'react';

function TextWithTitle({ title, text, fullWidth }) {
  return (
    <section className="section columns">
      <article className={`column block-content ${fullWidth ? '' : 'is-8'}`}>
        <h3 className="title is-3 mb-4">{title}</h3>
        <div dangerouslySetInnerHTML={{ __html: text }} />
      </article>
    </section>
  );
}

export default TextWithTitle;
