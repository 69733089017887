import React from 'react';

import AnimateLink from '../animateLink';

const NavigationBar = ({ previous, next }) => {
  return (
    <section className="block-content is-fullwidth has-background-black other-cases my-0 py-5">
      <p>TEST</p>
      <div className="columns is-mobile mx-6 my-5 pb-4">
        <div className="column is-half">
          {previous?.slug && (
            <>
              <AnimateLink url={previous.slug} cssClassLink="link prev">
                <span className="icon-arrow"></span> Previous case
              </AnimateLink>
              <p className="subtitle is-4 is-uppercase">
                {previous.client?.label}
              </p>
              <h1 className="title is-2">{previous.title}</h1>
            </>
          )}
        </div>
        <div className="column is-half has-text-right pb-4">
          {next?.slug && (
            <>
              <AnimateLink url={next.slug} cssClassLink="link next">
                Next case
                <span className="icon-arrow"></span>
              </AnimateLink>
              <p className="subtitle is-4 is-uppercase">{next.client?.label}</p>
              <h1 className="title is-2">{next.title}</h1>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default NavigationBar;
