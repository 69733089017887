import React, { useState } from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import PopinVideo from '../popin/popinVideo';

function MainPlaceholderVideo({ media }) {
  const [showPopin, setShowPopin] = useState(false);
  const image = getImage(media.visuel);

  const handleShowPopin = event => {
    event.preventDefault();
    setShowPopin(!showPopin);
  };

  // Get idVideo Youtube
  if (media?.videoLink) {
    var linkVideo = media.videoLink;
    linkVideo = linkVideo?.split('https://youtu.be/')[1];
  }

  return (
    <>
      {media && (
        <>
          {/* If background video */}
          {media.backgroundVideo && (
            <figure
              className="section is-16by9 block-content video"
              style={{ backgroundImage: `url(${image})` }}
            >
              <video autoPlay muted loop className="bkg-video">
                <source
                  src={media.backgroundVideo.file?.url}
                  type="video/mp4"
                />
              </video>

              {media.videoLink && (
                <div className="btn-container">
                  <a
                    href="#"
                    className="button is-outlined"
                    onClick={e => handleShowPopin(e)}
                  >
                    View video <span className="icon-play"></span>
                  </a>
                </div>
              )}
            </figure>
          )}

          {/* If not background video */}
          {!media.backgroundVideo && (
            <figure className="section is-16by9 block-content video">
              <GatsbyImage
                className="is-fullwidth-image"
                image={image}
                alt={media.visuel?.description}
              />
              {media.videoLink && (
                <div className="btn-container">
                  <a
                    href="/"
                    className="button is-outlined"
                    onClick={e => handleShowPopin(e)}
                  >
                    View video <span className="icon-play"></span>
                  </a>
                </div>
              )}
            </figure>
          )}
        </>
      )}
      <PopinVideo
        show={showPopin}
        handleShowPopin={handleShowPopin}
        idVideo={linkVideo}
      />
    </>
  );
}

export default MainPlaceholderVideo;
