import React, { Component } from 'react';
import { graphql } from 'gatsby';
import Helmet from 'react-helmet';

import Layout from '../components/layout';
import SEO from '../components/seo';
import HeroTitle from '../components/heroTitle';
import MainPlaceholderVideo from '../components/blockContent/mainPlaceholderVideo';
import TextWithTitle from '../components/textWithTitle';
import NavigationBar from '../components/navigation/navigationBar';
import BackToTop from '../components/navigation/backToTop';

class Project extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewAllOpen: false,
    };
  }

  render() {
    const { data } = this.props;

    const { project, navigationPrevious, navigationNext } = data;
    const navigation = {
      previous: { ...navigationPrevious },
      next: { ...navigationNext },
    };

    return (
      <>
        <Helmet>
          <body className="has-progress-bar" />
        </Helmet>
        <Layout
          headerCssClass="has-background-white"
          mainCssClass={`case-page ${
            this.state.showViewAllOpen ? 'with-block-view-all' : ''
          }`}
        >
          <SEO title={project.title} pageLink={project.slug} />
          <div className="project-sheet is-relative">
            {/* HeroTitle */}
            <HeroTitle
              subtitle={project.client?.label}
              title={project.title}
              catchphrase={project.catchphrase?.childMarkdownRemark?.html}
            />

            {/* MainPlaceholderVideo */}
            <MainPlaceholderVideo media={project.MainPlaceholderVideo} />

            {/* WorkPresentation */}
            <section className="section pt-4">
              {/* TextWithTitle - Challenge */}
              {project.challenge && (
                <TextWithTitle
                  title="Challenge"
                  text={project.challenge.childMarkdownRemark?.html}
                  fullWidth
                />
              )}

              {/* TextWithTitle - Idea */}
              {project.idea && (
                <TextWithTitle
                  title="Idea"
                  text={project.idea.childMarkdownRemark?.html}
                  fullWidth
                />
              )}

              {/* TextWithTitle - Result */}
              {project.result && (
                <TextWithTitle
                  title="Result"
                  text={project.result.childMarkdownRemark?.html}
                  fullWidth
                />
              )}
            </section>

            {/* BackToTop */}
            {!this.state.showViewAllOpen && <BackToTop />}
          </div>
          {/* NavigationBar */}
          <NavigationBar
            previous={navigation.previous}
            next={navigation.next}
          />
        </Layout>
      </>
    );
  }
}

export const query = graphql`
  query contentfulPageProjetTemplate(
    $contentfulId: String!
    $previous: String
    $next: String
  ) {
    project: contentfulPageProjet(contentful_id: { eq: $contentfulId }) {
      id
      node_locale
      title
      client {
        label
      }
      slug
      catchphrase {
        childMarkdownRemark {
          html
        }
      }
      challenge {
        childMarkdownRemark {
          html
        }
      }
      idea {
        childMarkdownRemark {
          html
        }
      }
      result {
        childMarkdownRemark {
          html
        }
      }
      MainPlaceholderVideo {
        visuel {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          description
        }
        backgroundVideo {
          file {
            url
          }
        }
        videoLink
      }
    }
    navigationPrevious: contentfulPageProjet(contentful_id: { eq: $previous }) {
      slug
      client {
        label
      }
      title
    }
    navigationNext: contentfulPageProjet(contentful_id: { eq: $next }) {
      slug
      client {
        label
      }
      title
    }
  }
`;

export default Project;
